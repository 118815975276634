import React from "react"

import { Link } from "gatsby"
import { motion } from "framer-motion"

export default ({ text, path }) => (
    <Link to={path}>
        <motion.div
            whileTap={{ scale: 0.95 }}
            className={`btn btn-black`}>
                {text}
        </motion.div>
    </Link>
)