import React from "react"
import { Link } from "gatsby"
import LogoAlt from "./LogoAlt"
import Button from "../components/Button"

export default ({ children, showSubFooter }) => (
  <div>
   { showSubFooter && <div className="p1 bg-blue-100">
        <div className="max-w-screen-xl mx-auto px-6 py-6">
            <div className="flex flex-row flex-wrap my-4">
                <div className="w-full py-6 pr-4 sm:w-1/2 sm:py-4">
                    <div className="font-bold text-2xl mb-2">Get started for free.</div>
                    <p className="mb-8">
                        No credit card required to start.
                    </p>
                    <a href="https://dashboard.counterset.com/#/signup" className="items-center btn btn-black">Get Started</a>
                </div>
                <div className="w-full my-6 pr-4 sm:w-1/2 sm:my-4 sm:border-l-2 sm:pl-16">
                    <div className="font-bold text-2xl mb-2">Schedule a demo.</div>
                    <p className="mb-8">
                        Get a customized product demo.
                    </p>
                    <a style={{"cursor": "pointer"}} href="mailto:will@counterset.com" className="items-center btn btn-black">Request a demo</a>
                </div>
            </div>
        </div>
    </div> 
    }
    <div className="p1 bg-gray-900">
        <div className="max-w-screen-xl mx-auto px-6 pt-12 pb-12 flex flex-row flex-wrap">
            <div className="w-full flex sm:w-3/4 pr-4 py-4">
                <Link to="/"><LogoAlt></LogoAlt></Link>
            </div>
            <div className="w-full flex sm:justify-end sm:w-1/4 py-4 text-white">
                <div className="w-full flex flex-wrap text-right" style={{maxWidth: 420}}>
                    <div className="w-1/2 sm:w-1/2 pr-4">
                        <Link to="/" className="block flex hover:text-indigo-500">Home</Link>
                        <Link to="/features" className="block flex hover:text-indigo-500">Features</Link>
                        <Link to="/pricing" className="block flex hover:text-indigo-500">Pricing</Link>
                        <Link to="/qr" className="block flex hover:text-indigo-500">QR Code</Link>
                        <a href="https://dashboard.counterset.com/#/signup" className="block flex hover:text-indigo-500">Sign Up</a>
                        <a href="https://dashboard.counterset.com/#/login" className="block flex hover:text-indigo-500">Log In</a>

                    </div>
                    <div className="w-1/2 pt-6 sm:pt-0 sm:w-1/2">
                        {/* <div className="flex sm:block text-gray-500">Company</div> */}
                        <Link to="/about" className="flex sm:block hover:text-indigo-500">About</Link>
                        <a href="mailto:will@counterset.com" className="flex sm:block hover:text-indigo-500">Contact Us</a>
                        <br />
                        <a href="https://docs.counterset.com" className="flex sm:block hover:text-indigo-500">API Docs</a>

                        {/* <Link to="/news" className="flex sm:block hover:text-indigo-500">Newsroom</Link> */}
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div className="p1 bg-black">
        <div className="flex md:flex-row-reverse flex-wrap max-w-screen-xl mx-auto px-6">
            <div className="w-full md:w-2/4 py-6 text-white md:justify-end flex flex-grow">
                <Link to="/terms" className="block flex hover:text-indigo-500">Terms</Link>
                <Link to="/privacy" className="ml-8 block flex hover:text-indigo-500">Privacy</Link>
            </div>
            <div className="w-full md:w-2/4 text-white py-6">
                <div className="w-full">
                    © 2021 CounterSet | Made in 🦘
                </div>
                <div className="w-full text-sm font-thin">
                    The word “QR Code” is a registered trademark of DENSO WAVE INCORPORATED in Japan and other countries.
                </div>
            </div>
        </div>
    </div>
  </div>
)