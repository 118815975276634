import React from "react"

import Metadata from "../Metadata"
import Navigation from "../Navigation"
import Footer from "../Footer"

export default ({ header, children, showSubFooter = true, splash = false }) => (
  <>
    <Metadata />
    <Navigation></Navigation>
    { header }
    <div className="flex flex-col justify-between">
      { splash ?
        <div className="w-full">
          { children }
        </div>
      : <div className="w-full max-w-screen-xl mx-auto px-6">
        { children }
        </div>
      }
      <Footer showSubFooter={showSubFooter}></Footer>
    </div>
  </>
)