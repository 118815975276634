import React from "react"
import { Helmet } from "react-helmet"
import { graphql, useStaticQuery } from "gatsby"

const SiteMetadata = ({}) => {
    const {
        site: {
            siteMetadata: { siteUrl, title, twitter },
        },
    } = useStaticQuery(graphql`
        query SiteMetadata {
            site {
                siteMetadata {
                    siteUrl
                    title
                    twitter
                }
            }
        }
    `)

    return (
      <Helmet defer={false} defaultTitle={title} titleTemplate={`${title} | %s`}>
        <html lang="en" />
        <meta name="docsearch:version" content="2.0" />
        <meta
          name="viewport"
          content="width=device-width,initial-scale=1,shrink-to-fit=no,viewport-fit=cover"
        />
  
        <meta property="og:url" content={siteUrl} />
        <meta property="og:type" content="website" />
        <meta property="og:locale" content="en" />
        <meta property="og:site_name" content="CounterSet" />
        <meta property="og:title" content={title} />

        {/* <meta property="og:image" content={`${siteUrl}${gatsbyIcon}`} />
        <meta property="og:image:width" content="512" />
        <meta property="og:image:height" content="512" /> */}
  
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content={twitter} />
        <script type="application/ld+json">{`
          {
          "@context": "https://schema.org",
            "@type": "Organization",
            "name": "CounterSet",
            "url": "https://www.counterset.com",
            "sameAs": [
              "https://twitter.com/counterset",
              "https://instagram.com/counterset"
            ]
          }`}</script>
      </Helmet>
    )
  }
  
  export default SiteMetadata