import React, { useState } from "react"
import { Link } from "gatsby"
import Logo from "./Logo"

export default ({ children }) => {
  const [isExpanded, toggleExpansion] = useState(false)

  return (
    <nav className="flex bg-white border-b border-gray-200 top-0 inset-x-0 py-4" style={{
      position: "sticky",
      zIndex: 11
    }}>
      <div className="w-full max-w-screen-xl relative mx-auto flex flex-row flex-wrap px-6">
        <div className="flex items-center flex-shrink-0 text-white mr-6">
          <Link to="/"><Logo></Logo></Link>
        </div>
        <div className="flex flex-grow justify-end md:hidden">
          <button onClick={() => toggleExpansion(!isExpanded)} className="flex px-2 py-2 text-gray-900 hover:text-indigo-500">
            <svg className="fill-current h-6 w-6" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>Menu</title><path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"/></svg>
          </button>
        </div>
        <div className={`${isExpanded ? 'block w-full' : 'hidden'} md:w-auto md:flex md:flex-grow items-center`}>
          <div className={`${isExpanded ? 'block w-full py-6' : 'flex'} md:py-0 md:flex md:flex-grow md:justify-end mr-8`}>
            <Link to="/features" className="border-b border-gray-200 py-2 md:py-0 md:border-none md:ml-8 block items-center font-medium text-gray-900 hover:text-indigo-500">Features</Link>
            <Link to="/pricing" className="border-t border-b border-gray-200 py-2 md:py-0 md:border-none md:ml-8 block items-center font-medium text-gray-900 hover:text-indigo-500">Pricing</Link>
            <Link to="/qr" className="border-b border-gray-200 py-2 md:py-0 md:border-none md:ml-8 block items-center font-medium text-gray-900 hover:text-indigo-500">QR Generator</Link>
          <div className="relative group block flex items-center">
        <div className="border-b border-gray-200 py-2 md:py-0 md:border-none md:ml-8 block flex items-center font-medium text-gray-900 hover:text-gray-700">Solutions</div>
        <div className="absolute mt-40 shadow-md rounded-s p-1 bg-white p-2 invisible group-hover:visible" style={{paddingTop: 0.5 + 'rem', marginTop: 9 + 'rem'}}>
          <Link to="/cbd" className="px-4 py-2 block text-black hover:text-indigo-500">CBD/Hemp</Link>
          <Link to="/bws" className="px-4 py-2 block text-black hover:text-indigo-500">Beer, Wine, and Spirits</Link>
        </div>
        </div>
          </div>
          <div className="hidden md:block">
            <a href="https://dashboard.counterset.com/#/signup" className="px-4 py-2 leading-none items-center btn btn-black">Get Started</a>
          </div>
        </div>
      </div>
    </nav>
  )
}